// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCGCN1SvBO1ti71QzSTRitlrMZ7eEA0U3c",
  authDomain: "iserenify-46892.firebaseapp.com",
  projectId: "iserenify-46892",
  storageBucket: "iserenify-46892.appspot.com",
  messagingSenderId: "172141235339",
  appId: "1:172141235339:web:1f7e34874df9812349988e",
  measurementId: "G-FC0HZ8KVQL",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const provider_fb = new firebase.auth.FacebookAuthProvider();
const provider_google = new firebase.auth.GoogleAuthProvider();
const provider_apple = new firebase.auth.GoogleAuthProvider();
const storage = getStorage();

export {
  auth,
  provider,
  provider_fb,
  provider_google,
  provider_apple,
  storage,
};

export default db;
